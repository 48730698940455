<template>
    <main>
        <Header></Header>
        <div class="card mb-">
            <Timeline :tasks="tasks"></Timeline>
        </div>
    </main>
</template>

<script>
import Header from "@/components/Header/Header.vue";
import Timeline from "@/components/Timeline/Timeline.vue";
import { getProjectPhaseTimeline } from "@/api/bookings";
import { createTaskListFromApiData } from "@/service/Timeline";
import { toast } from "vue3-toastify";

export default {
    name: 'BookingPhaseTimeline',
    components: {
        Header,
        Timeline,
    },
    data() {
        return {
            phaseId: null,
            tasks: [],
        }
    },
    methods: {

    },
    async created() {
        this.phaseId = this.$route.params.id;

        try {
            const { data } = await getProjectPhaseTimeline(this.phaseId);
            this.tasks = createTaskListFromApiData(data.data, data.start_date, data.end_date);
        } catch (e) {
            toast.error(e.response?.data.message);
        }
    },
}
</script>

<style>

</style>