<template>
    <main :class="{'h-100': loadPage}">
        <Header></Header>
        <EstimateForm />
    </main>
</template>
<script> 
import Header from '@/components/Header/Header.vue';
import EstimateForm from '../estimations/EstimationForm.vue';

export default {
    name: "AddEstimate",
    components: {
        Header,
        EstimateForm,
    }, 
    data() {
        return {
            loadPage: true,
        }
    },
    methods: {
        loading(status) {
            this.loadPage = status
        },
    },
}
</script>
<style lang="scss" scoped>

</style>