import { datePickerFormatFromString } from "@/service/DateTime";

const MIN_HOURS = 0;
const MAX_HOURS = 9999;
const MIN_HOURS_PER_DAY = 0;
const MAX_HOURS_PER_DAY = 8;
const VALIDATE_ANALYSIS_DATA_ERROR = 'Analysis date error: ';
const API_ERROR_RENDER_INTERVAL = 750;

export const SINGLE_BOOKING_CALC_MODE = {
    HOURS: 'hours',
    HOURS_PER_DAY: 'hours-per-day',
    START_DATE: 'start-date',
    END_DATE: 'end-date',
};

export const TIMELINE_MODE = {
    RESOURCE: 'resource',
    PHASE: 'phase',
};

export const TIMELINE_INTERVAL = {
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year',
};

export const validateAnalysisData = (data) => {
    if (!data) {
        throw VALIDATE_ANALYSIS_DATA_ERROR + 'No data';
    }

    if (!data.skills || !Array.isArray(data.skills)) {
        throw VALIDATE_ANALYSIS_DATA_ERROR + 'No skills';
    }

    data.skills.forEach(skill => {
        if (!skill || !Array.isArray(skill.resources)) {
            throw VALIDATE_ANALYSIS_DATA_ERROR + 'No skill resources';
        }

        skill.resources.forEach(resource => {
            if (typeof resource.available_hours === 'udefined') {
                throw VALIDATE_ANALYSIS_DATA_ERROR + 'No resource available hours';
            }
        });
    });
}

export const initTimeAllocationPageData = (analysisData) => {
    const initData = {
        skills: [],
        start_date: datePickerFormatFromString(analysisData.start_date),
    };

    if (analysisData.end_date) {
        initData.end_date = datePickerFormatFromString(analysisData.end_date);
    }

    analysisData.skills.forEach(skillData => {
        const skill = {
            id: skillData.id,
            resources: [],
            hours: 0,
            start_date: datePickerFormatFromString(skillData.start_date),
            accordionActive: true,
        };

        if (skillData.ongoing == 1) {
            skill.ongoing = true;
        } else {
            skill.ongoing = false;
        }

        if (skillData.end_date) {
            skill.end_date = datePickerFormatFromString(skillData.end_date);
        }

        skillData.resources.forEach(resourceData => {
            const resource = {
                id: resourceData.id,
                start_date: null,
                end_date: null,
                hours_per_day: 0,
                hours: 0,
            };

            skill.resources.push(resource);
        });

        initData.skills.push(skill);
    });

    return initData;
}

export const initTimeAllocationPageErrors = (allocationData) => {
    const errors = {
        skills: [],
    };

    allocationData.skills.forEach((skillData, skillIndex) => {
        errors.skills[skillIndex] = {
            id: skillData.id,
            resources: [],
            message: null,
            hours: 0,
            fields: {
                hours: false,
            },
        };

        skillData.resources.forEach((resourceData, resourceIndex) => {
            errors.skills[skillIndex].resources[resourceIndex] = {
                id: resourceData.id,
                fields: {
                    available_hours: false,
                    hours: false,
                    hours_per_day: false,
                    start_date: false,
                    end_date: false,
                },
            };
        });
    });

    return errors;
}

export const initAccordionData = (analysisData) => {
    const data = {
        project: true,
        allocation: true,
        skills: [],
    };

    analysisData.skills.forEach((skillData) => {
        data.skills[skillData.id] = true;
    });

    return data;
}

export const clearAllocationErrors = (allocationErrors) => {
    if (!allocationErrors.skills || !Array.isArray(allocationErrors.skills)) {
        return;
    }

    allocationErrors.skills.forEach(skillData => {
        skillData.message = null;
        skillData.fields.hours = false;

        skillData.resources.forEach(resourceData => {
            resourceData.fields = {
                available_hours: false,
                hours: false,
                hours_per_day: false,
                start_date: false,
                end_date: false,
            };
        });
    });
}

export const allocateResources = (analysisData, allocationData, allocationErrors) => {
    clearAllocationErrors(allocationErrors);
    const numErrors = validateAllocation(analysisData, allocationData, allocationErrors);

    if (!allocationData.skills || !Array.isArray(allocationData.skills)) {
        return numErrors;
    }

    allocationData.skills.forEach((skillData) => {
        let skillHours = 0;

        skillData.resources.forEach((resourceData) => {
            if (Number.isInteger(resourceData.hours)) {
                skillHours += resourceData.hours;
            }
        });

        skillData.hours = skillHours;
    });

    return numErrors;
}

export const findAnalysisSkill = (analysisData, skillId) => {
    let skill = null;

    if (!analysisData.skills || !Array.isArray(analysisData.skills)) {
        return skill;
    }

    analysisData.skills.forEach((skillData) => {
        if (skillData.id == skillId) {
            skill = skillData;
        }
    });

    return skill;
}

export const findAnalysisResource = (analysisData, resourceId) => {
    let resource = null;

    analysisData.skills.forEach((skillData) => {
        skillData.resources.forEach((resourceData) => {
            if (resourceData.id == resourceId) {
                resource = resourceData;
            }
        });
    });

    return resource;
}

export const findAllocatedSkill = (allocationData, skillId) => {
    let skill = null;

    allocationData.skills.forEach((skillData) => {
        if (skillData.id == skillId) {
            skill = skillData;
        }
    });

    return skill;
}

export const findAllocatedResource = (allocationData, resourceId, skillId) => {
    let resource = null;

    allocationData.skills.forEach((skillData) => {
        skillData.resources.forEach((resourceData) => {
            if (!skillId && resourceData.id == resourceId) {
                resource = resourceData;
            }
            if (skillId && skillData.id == skillId && resourceData.id == resourceId) {
                resource = resourceData;
            }
        });
    });

    return resource;
}

export const findAllocatedErrorSkill = (allocatedErrors, skillId) => {
    let skill = null;

    if (!allocatedErrors.skills || !Array.isArray(allocatedErrors.skills)) {
        return skill;
    }

    allocatedErrors.skills.forEach((skillData) => {
        if (skillData.id == skillId) {
            skill = skillData;
        }
    });

    return skill;
}

export const findAllocatedErrorResource = (allocatedErrors, resourceId, skillId) => {
    let resource = null;

    if (!allocatedErrors.skills || !Array.isArray(allocatedErrors.skills)) {
        return resource;
    }

    allocatedErrors.skills.forEach((skillData) => {
        skillData.resources.forEach((resourceData) => {
            if (!skillId && resourceData.id == resourceId) {
                resource = resourceData;
            }

            if (skillId && skillData.id == skillId && resourceData.id == resourceId) {
                resource = resourceData;
            }
        });
    });

    return resource;
}

export const allocateSingleResourceByData = (allocationData, newRawResourceData, allocationErrors) => {
    const resourceData = findAllocatedResource(allocationData, newRawResourceData.data.resource_id, newRawResourceData.data.skill_id);

    if (newRawResourceData.data.mode == SINGLE_BOOKING_CALC_MODE.HOURS) {
        resourceData.hours = newRawResourceData.data.hours;
    }

    if (newRawResourceData.data.mode == SINGLE_BOOKING_CALC_MODE.HOURS_PER_DAY) {
        resourceData.hours_per_day = newRawResourceData.data.hours_per_day;
    }

    if (newRawResourceData.data.mode == SINGLE_BOOKING_CALC_MODE.START_DATE) {
        resourceData.start_date = datePickerFormatFromString(newRawResourceData.data.start_date);
    }

    if (newRawResourceData.data.mode == SINGLE_BOOKING_CALC_MODE.END_DATE) {
        resourceData.end_date = datePickerFormatFromString(newRawResourceData.data.end_date);
    }

    setTimeout(() => {
        if (newRawResourceData.errors && Array.isArray(newRawResourceData.errors)) {
            newRawResourceData.errors.forEach((apiErrorData) => {
                const errorData = findAllocatedErrorResource(allocationErrors, apiErrorData.id, newRawResourceData.data.skill_id);

                if (errorData) {
                    if (apiErrorData.field == SINGLE_BOOKING_CALC_MODE.HOURS) {
                        errorData.fields.hours = true;
                    }
                    if (apiErrorData.field == SINGLE_BOOKING_CALC_MODE.HOURS_PER_DAY) {
                        errorData.fields.hours_per_day = true;
                    }
                    if (apiErrorData.field == SINGLE_BOOKING_CALC_MODE.START_DATE) {
                        errorData.fields.start_date = true;
                    }
                    if (apiErrorData.field == SINGLE_BOOKING_CALC_MODE.END_DATE) {
                        errorData.fields.end_date = true;
                    }
                }
            });
        }
    }, API_ERROR_RENDER_INTERVAL);
}

export const validateAllocation = (analysisData, allocationData, allocationErrors) => {
    let numErrors = 0;
    const checkAllResources = [];

    if (!allocationData.skills || !Array.isArray(allocationData.skills)) {
        return numErrors;
    }

    allocationData.skills.forEach((skillData, skillIndex) => {
        if (!skillData.ongoing) {
            if (skillData.hours < analysisData.skills[skillIndex].hours) {
                allocationErrors.skills[skillIndex].message = 'Allocate all required hours';
                numErrors ++;
            }

            if (skillData.hours > analysisData.skills[skillIndex].hours) {
                allocationErrors.skills[skillIndex].fields.hours = true;
                allocationErrors.skills[skillIndex].message = 'Invalid time allocation';
                numErrors ++;
            }
        }

        skillData.resources.forEach((resourceData, resourceIndex) => {
            if (typeof checkAllResources[resourceData.id] === 'undefined') {
                checkAllResources[resourceData.id] = {
                    hours_per_day: 0,
                };
            }

            checkAllResources[resourceData.id].hours_per_day += resourceData.hours_per_day;

            if (resourceData.hours > 0) {
                if (resourceData.hours_per_day < 1 || resourceData.hours_per_day > 8) {
                    allocationErrors.skills[skillIndex].resources[resourceIndex].fields.hours_per_day = true;
                    numErrors ++;
                }
                if (!skillData.ongoing) {
                    if (analysisData.skills[skillIndex].resources[resourceIndex].available_hours < resourceData.hours) {
                        allocationErrors.skills[skillIndex].resources[resourceIndex].fields.available_hours = true;
                        numErrors ++;
                    }
                }
            }
        });
    });

    // allocationData.skills.forEach((skillData, skillIndex) => {
    //     skillData.resources.forEach((resourceData, resourceIndex) => {
    //         if (checkAllResources[resourceData.id].hours_per_day > MAX_HOURS_PER_DAY) {
    //             allocationErrors.skills[skillIndex].resources[resourceIndex].fields.hours_per_day = true;
    //             numErrors ++;
    //         }
    //     });
    // });

    return numErrors;
}

export const forceAllocationInputs = (allocationData) => {
    if (allocationData && allocationData.skills && Array.isArray(allocationData.skills)) {
        allocationData.skills.forEach((skillData) => {
            skillData.resources.forEach((resourceData) => {
                if (resourceData.hours < MIN_HOURS) {
                    resourceData.hours = MIN_HOURS;
                }
                if (resourceData.hours > MAX_HOURS) {
                    resourceData.hours = MAX_HOURS;
                }
                if (resourceData.hours_per_day < MIN_HOURS_PER_DAY) {
                    resourceData.hours_per_day = MIN_HOURS_PER_DAY;
                }
                if (resourceData.hours_per_day > MAX_HOURS_PER_DAY) {
                    resourceData.hours_per_day = MAX_HOURS_PER_DAY;
                }
            });
        });
    }
}

export const trimSubmitData = (data) => {
    const trimmed = {
        skills: [],
    };

    data.skills.forEach((skillData) => {
        const skillTrimmedData = {
            id: skillData.id,
            resources: [],
        };

        skillData.resources.forEach((resourceData) => {
            const trimmedResourceData = {
                id: resourceData.id,
            };

            if (resourceData.hours_per_day && resourceData.start_date) {
                trimmedResourceData.hours_per_day = resourceData.hours_per_day;
                trimmedResourceData.start_date = resourceData.start_date;

                if (resourceData.hours) {
                    trimmedResourceData.hours = resourceData.hours;
                }

                if (resourceData.end_date) {
                    trimmedResourceData.end_date = resourceData.end_date;
                }

                skillTrimmedData.resources.push(trimmedResourceData);
            }
        });

        if (skillTrimmedData.resources.length > 0) {
            trimmed.skills.push(skillTrimmedData);
        }
    });

    return trimmed;
}

export const assignApiErrors = (allocationErrors, data) => {
    if (!data || !data.errors || !data.errors.skills) {
        return;
    }

    for (const [skillKey, skillData] of Object.entries(data.errors.skills)) {
        const skillErrorData = findAllocatedErrorSkill(allocationErrors, skillKey);

        if (skillErrorData) {
            if (skillData.message) {
                skillErrorData.message = skillData.message;
            }

            if (skillData.resources && Array.isArray(skillData.resources)) {
                skillData.resources.forEach((resourceData) => {
                    const resourceErrorData = findAllocatedErrorResource(allocationErrors, resourceData.id, skillKey);
                    if (resourceErrorData) {
                        if (resourceData.field) {
                            resourceErrorData.fields[resourceData.field] = true;
                        }
                    }
                });
            }
        }
    }
};
