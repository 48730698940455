<template>
    <main :class="{'h-100': loadPage}">
        <header class="mb">
            <router-link
              active-class="active"
              class="nav__link"
              :to="{name: 'dashboard'}"
            >
                Team Utilization & Cost
            </router-link>
            <router-link
              active-class="active"
              class="nav__link"
              :to="{name: 'estimations-dashboard'}"
            >
                Estimations
            </router-link>
        </header>
        <div class="card">
            <div v-if="!loadPage" class="card__header card--border o-flex o-flex--center o-flex--justify o-flex--wrap pt-">
                <div class="flag">
                    <div class="flag__icon">
                        <img src="@/assets/img/dashboard-chart.svg" alt="dashboard-chart" />
                    </div>
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">Your team is currently utilized in <span class="clr-light-blue">28%</span></h2>
                        <p class="mb0">Check who's free in the upcoming weeks</p>
                    </div>
                </div>
                <div class="search-box">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                        d="M17 17L21 21M11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 13.2053 18.1077 15.2022 16.6644 16.6493C15.2161 18.1015 13.213 19 11 19Z"
                        stroke="#637083"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        />
                    </svg>
                    <input
                        type="search"
                        class="input input--primary input--search"
                        placeholder="Search"
                        v-model="searchTerm" 
                        @input="debouncedOnSearch"
                    />
                </div>
            </div>

            <div class="card__body" :class="{'h-100': loadPage}">
                <Loader v-if="loadPage"/>
                <div v-else class="o-flex wrapper-box">
                    <VueApexCharts 
                        type="donut" 
                        :options="donutOptions" 
                        :series="donutSeries"
                        />
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                            <tr>
                                <th width="30%">Name</th>
                                <th>14/10 - 18/10</th>
                                <th>21/10 - 25/10</th>
                                <th>28/10 - 01/11</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <p class="mb0 u-font-500">
                                            Nikola Skaramuca
                                        </p>
                                    </td>
                                    <td>
                                        <p class="mb0 u-font-500">
                                            22h
                                        </p>
                                    </td>
                                    <td>
                                        <span class="clr-light-red u-font-500">0h</span>
                                    </td>
                                    <td>
                                        <span class="clr-light-gray u-font-500">40h</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="mb0 u-font-500">
                                            Nikola Skaramuca
                                        </p>
                                    </td>
                                    <td>
                                        <p class="mb0 u-font-500">
                                            22h
                                        </p>
                                    </td>
                                    <td>
                                        <span class="clr-light-red u-font-500">0h</span>
                                    </td>
                                    <td>
                                        <span class="clr-light-gray u-font-500">40h</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="mb0 u-font-500">
                                            Nikola Skaramuca
                                        </p>
                                    </td>
                                    <td>
                                        <p class="mb0 u-font-500">
                                            22h
                                        </p>
                                    </td>
                                    <td>
                                        <span class="clr-light-red u-font-500">0h</span>
                                    </td>
                                    <td>
                                        <span class="clr-light-gray u-font-500">40h</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p class="mb0 u-font-500">
                                            Nikola Skaramuca
                                        </p>
                                    </td>
                                    <td>
                                        <p class="mb0 u-font-500">
                                            22h
                                        </p>
                                    </td>
                                    <td>
                                        <span class="clr-light-red u-font-500">0h</span>
                                    </td>
                                    <td>
                                        <span class="clr-light-gray u-font-500">40h</span>
                                    </td>
                                </tr>
                            <!-- <tr v-for="item in data" :key="item.id">
                                <td>{{item.name}}</td>
                                <td>{{formatDate(item.created_at)}}</td>
                                <td>
                                <StatusBox :status="item.status"/>
                                </td>
                                <td>
                                <ul
                                    class="o-flex o-flex--center o-flex--justify-center action-list"
                                >
                                    <li>
                                    <a href="javascript:;" class="icon-trigger" @click="editModalHandler(item.id, item.name, item.status)">
                                        <SvgIcon  :icon="svgIcons.edit"
                                            icon-width="20px"
                                            icon-height="20px"/>
                                    </a>
                                    </li>
                                    <li>
                                    <a href="javascript:;" class="icon-trigger" @click="deleteModalHandler(item.id, item.name)">
                                        <SvgIcon  :icon="svgIcons.delete"
                                            icon-width="20px"
                                            icon-height="20px"/>
                                    </a>
                                    </li>
                                </ul>
                                </td>
                            </tr> -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div> 

        <div class="card mb0">
            <div class="card__header card--border o-flex o-flex--center o-flex--justify o-flex--wrap pt-">
                <div class="flag">
                    <div class="flag__icon">
                        <img src="@/assets/img/send-dollars.svg" alt="send-dollars" />
                    </div>
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">Total Cost of Unused Resources</h2>
                    </div>
                </div>
            </div>
            <div class="card__body">
                <VueApexCharts 
                    :height="250"
                    type="bar" 
                    :options="barOptions" 
                    :series="barSeries"
                    />
            </div>
        </div>
    </main>
</template>
<script>
import Loader from '@/components/Loader/Loader.vue';
import VueApexCharts from 'vue3-apexcharts';

export default {
    name: "TimeUtilization",
    components: {Loader, VueApexCharts},
    data() {
        return {
            loadPage: false,
            searchTerm: '',
            donutSeries: [28, 75],
            donutOptions: {
                fill: {
                    colors: ['#3B82F6', '#22C55E'],
                    opacity: 1,
                },
                chart: {
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        donut: {
                            size: '70%',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '22px',
                                    color: '#000',
                                },
                                value: {
                                    show: true,
                                    fontSize: '28px',
                                    fontWeight: 700,
                                    color: '#141C25',
                                    formatter: (val) => `${val}%`,
                                },
                                total: {
                                    show: true,
                                    label: 'Utilized',
                                    color: '#637083',
                                    fontSize: '14px',
                                    formatter: (val) => `${val.globals.seriesTotals[0]}%`,
                                },
                            },
                        },
                    },
                },

                dataLabels: {
                    enabled: false,
                },
                labels: ['Category A', 'Category B'], 
                legend: {
                    show: false,
                    position: 'bottom',
                },
            },
            barSeries: [{
                data: [{
                    x: 'Jan',
                    y: 1200
                }, {
                    x: 'Feb',
                    y: 1400
                }, {
                    x: 'Mar',
                    y: 4000
                }, {
                    x: 'Apr',
                    y: 2300
                }, {
                    x: 'May',
                    y: 2000
                }, {
                    x: 'Jun',
                    y: 4000
                }, {
                    x: 'Jul',
                    y: 2000
                }, {
                    x: 'Avg',
                    y: 1900
                }, {
                    x: 'Sep',
                    y: 1900
                }, {
                    x: 'Oct',
                    y: 4000
                }, {
                    x: 'Nov',
                    y: 1500
                }, {
                    x: 'Dec',
                    y: 2200
                }]
            }],
            barOptions: {
                fill: {
                    colors: ['#3B82F6'],
                    opacity: 1,
                },
                plotOptions: {
                    bar: {
                        distributed: true,
                        columnWidth: '10%'
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    show: false,
                    position: 'bottom',
                },
            }
        }
    },
    methods: {
        debouncedOnSearch() {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
            this.onSearch();
            }, 500);
        }
    }
}
</script>
<style lang="scss" scoped>
    header {
        display: flex;
        gap: 6px;

        .nav__link {
            padding: 0 16px;
            height: 32px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            color: #637083;

            &.exact-active {
                background-color: #fff;
                color: #141C25;
            }
        }
    }

    .card {
        margin-bottom: 24px;
    }

    .card__body {
        padding: 24px 0;
    }

    .wrapper-box {
        gap: 75px;

        .table-responsive {
            flex: 1;

            td {
                padding: 20px;
            }
        }
    }
</style>