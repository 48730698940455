<template>
    <main :class="{'h-100': loadPage}">
        <section class="filter-wrapper o-flex o-flex--center o-flex--justify o-flex--wrap gap-10">
            <div class="search-box">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                    d="M17 17L21 21M11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 13.2053 18.1077 15.2022 16.6644 16.6493C15.2161 18.1015 13.213 19 11 19Z"
                    stroke="#637083"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    />
                </svg>
                <input
                    type="search"
                    class="input input--primary input--search"
                    placeholder="Search projects"
                />
            </div>
        
            <div class="filter-wrapper__btns o-flex o-flex--center">
                <div class="filter mr-" v-click-outside="dropdownClose">
                    <button @click="dropdown = true" class="button button--secondary" :class="{'active' : activeFilters}">
                        <svg width="20" height="20" class="mr-" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.50004 5.83333V3.5C2.50004 2.94772 2.94775 2.5 3.50004 2.5H16.5001C17.0524 2.5 17.5001 2.94766 17.5001 3.4999L17.5004 5.83333M2.50004 5.83333L7.98416 10.534C8.20581 10.724 8.33337 11.0013 8.33337 11.2933V16.2192C8.33337 16.8698 8.94476 17.3472 9.57591 17.1894L10.9092 16.856C11.3544 16.7447 11.6667 16.3448 11.6667 15.8859V11.2933C11.6667 11.0014 11.7943 10.724 12.0159 10.534L17.5004 5.83333M2.50004 5.83333H17.5004" stroke="#344051" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Filter
                    </button>
                    <transition name="fade">
                        <div class="filter__dropdown" v-if="dropdown">
                            <div class="filter__header o-flex o-flex--end o-flex--justify">
                                <div class="o-flex o-flex--center gap-10">
                                    <img src="../../assets/img/filter-dropdown.svg" alt="">
                                    <h2 class="u-font-500 mb0">Filter</h2>
                                </div>
                                <a v-if="activeFilters" href="javascript:;" @click="clearAll" class="clear">Reset All</a>
                            </div>
                            <div class="filter__body">
                                    <h3>Priority</h3>
                            </div>
                            <div class="filter__footer o-flex o-flex--justify-center gap-10">
                                <button type="button" class="button button--secondary" @click="dropdownClose">Cancel</button>
                                <button type="button" class="button button--primary">Apply</button>
                            </div>
                        </div>
                    </transition>
                </div>
                <router-link
                    :to="{name: 'add-estimation'}"
                    class="button button--primary">
                    <svg fill="none" width="20" viewBox="0 0 20 20">
                        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5 10h5m5 0h-5m0 0V5m0 5v5"/>
                    </svg>
                    Add new
                </router-link>
            </div>
        </section>
        <div class="card">
            <div class="card__header o-flex o-flex--center o-flex--justify">
                <div class="flag">
                    <div class="flag__icon">
                    <img src="@/assets/img/project.svg" alt="list" />
                    </div>
                    <div class="flag__content">
                    <h2 class="u-font-500 mb0">Estimations</h2>
                    </div>
                </div>
            </div>
            <div class="card__body" :class="{'h-100': loadPage}">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Manager</th>
                                <th>Total</th>
                                <th>Status</th>
                                <th>Description</th>
                                <th width="10%" class="u-text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Estimation 1
                                </td>
                                <td>
                                    Ivana Vojnic
                                </td>
                                <td>
                                    $4,000.00
                                </td>
                                <td>
                                    <StatusBox :status="'won'"/>
                                </td>
                                <td>
                                    <a href="#" class="view-skills">
                                        <svg fill="none" width="14" viewBox="0 0 14 10">
                                            <path stroke="#B3D7FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 6.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666Z"/>
                                            <path stroke="#B3D7FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 5c-1.259 1.994-3.521 4-6 4S2.259 6.994 1 5c1.532-1.894 3.328-4 6-4s4.468 2.105 6 4Z"/>
                                        </svg>
                                        View Skills
                                    </a>
                                </td>
                                <td class="u-text-center">
                                    <a href="#">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.9693 4.70964L13.2026 3.47631C13.8535 2.82544 14.9088 2.82544 15.5597 3.47631L16.7382 4.65483C17.3891 5.3057 17.3891 6.36097 16.7382 7.01185L15.5049 8.24517M11.9693 4.70964L3.95627 12.7227C3.67954 12.9994 3.50902 13.3647 3.47458 13.7545L3.27284 16.0382C3.2272 16.5549 3.6596 16.9873 4.17627 16.9417L6.45996 16.7399C6.84979 16.7055 7.21508 16.535 7.49181 16.2582L15.5049 8.24517M11.9693 4.70964L15.5049 8.24517" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </a>
                                </td>
                            </tr>
                            <!-- <tr v-for="project in data" :key="project.id">
                                <td>
                                    <div class="o-flex o-flex--center">
                                        <span class="color-box-mark mr-" :style="`background-color: ${project.color}`"></span>
                                        {{ project.name }}
                                    </div>
                                </td>
                                <td>
                                    {{project.project_manager.first_name}} {{project.project_manager.last_name}}
                                </td>
                                <td>
                                    <PriorityBox :priority="project.priority"/>
                                </td>
                                <td>
                                    <StatusBox :status="project.status"/>
                                </td>
                                <td class="u-text-center">
                                    <router-link :to="{ name: 'project-view-edit', params: { id: project.id } }" @click.prevent="updateEditProjectId(project.id)"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.9693 4.70964L13.2026 3.47631C13.8535 2.82544 14.9088 2.82544 15.5597 3.47631L16.7382 4.65483C17.3891 5.3057 17.3891 6.36097 16.7382 7.01185L15.5049 8.24517M11.9693 4.70964L3.95627 12.7227C3.67954 12.9994 3.50902 13.3647 3.47458 13.7545L3.27284 16.0382C3.2272 16.5549 3.6596 16.9873 4.17627 16.9417L6.45996 16.7399C6.84979 16.7055 7.21508 16.535 7.49181 16.2582L15.5049 8.24517M11.9693 4.70964L15.5049 8.24517" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg></router-link>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card__footer" v-if="!loadPage">
                <!-- <Pagination @page-click="getProjects" :info="pagination" /> -->
            </div>
        </div>
    </main>
</template>
<script>
    import Loader from '../../components/Loader/Loader.vue';
    import StatusBox from '../../components/StatusBox/StatusBox.vue';
    export default {
        name:"EstimationsDashboard",
        components: {
            Loader,
            StatusBox,
        },
        data() {
            return {
                loadPage: true,
                dropdown:false,
            }
        },
        computed: {
            activeFilters() {

            },
        },
        methods: {
            dropdownClose() {
                if(this.dropdown) {
                    this.dropdown = false
                }
            },
        }
    }
</script>
<style lang="scss" scoped>

</style>