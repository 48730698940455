import './assets/main.css'
import "@/assets/scss/app.scss";
import 'vue3-toastify/dist/index.css';
import axios from "axios";
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { clientLogout } from './service/Auth';
import { clickOutside } from "@/directives";
import { toast } from 'vue3-toastify';

axios.defaults.baseURL = import.meta.env.VITE_APP_API_URL;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('auth_token');
axios.interceptors.response.use(
    response => response, 
    async (error) => {
      console.log(error)
      if (error?.status === 401) {
        toast.info('Session expired. Please log in again.', {"position": "top-center", autoClose: 2000});
        await clientLogout();
        
        return await router.push({ name: 'login' });
      }
      return Promise.reject(error);
    }
);
createApp(App).use(store).directive('click-outside', clickOutside).use(router).mount('#app')
