import axios from "axios";

export const getTestDataApi = () => {
    return axios.get(`/api/admin/bookings/test`);
}

export const getAllProjectsApi = () => {
    return axios.get(`/api/admin/projects/all`);
}

export const getProjectPhaseAnalysisApi = (phaseId) => {
    return axios.get(`/api/admin/bookings/analyze-project-phase/` + phaseId);
}

export const getSingleBookingDateRangeCalc = (phaseId, skillId, resourceId, hours, hoursPerDay, startDate, endDate, mode) => {
    const data = {
        skill_id: skillId,
        resource_id: resourceId,
        hours,
        hours_per_day: hoursPerDay,
    };

    if (startDate) {
        data.start_date = startDate;
    }

    if (endDate) {
        data.end_date = endDate;
    }

    if (mode) {
        data.mode = mode;
    }

    return axios.post(`/api/admin/bookings/calculate-single-booking-date-range/` + phaseId, data);
}

export const analyzeProjectPhase = (phaseId, data) => {
    return axios.post(`/api/admin/bookings/date-skills-project-phase/` + phaseId, data);
}

export const bookProjectPhase = (phaseId, data) => {
    return axios.post(`/api/admin/bookings/book-project-phase/` + phaseId, data);
}

export const getProjectPhaseTimeline = (phaseId) => {
    return axios.get(`/api/admin/bookings/view-project-phase/` + phaseId);
}

export const getTimeline = (params) => {
    const skillIds = params.skill_ids || [];
    delete params.skill_ids;

    const teamIds = params.team_ids || [];
    delete params.team_ids;

    const resourceIds = params.resource_ids || [];
    delete params.resource_ids;

    let urlParams = new URLSearchParams(params).toString();

    if (urlParams) {
        urlParams = '?' + urlParams;

        skillIds.map((skillId) => {
            urlParams += '&skill_ids[]=' + skillId;
        });

        teamIds.map((teamId) => {
            urlParams += '&team_ids[]=' + teamId;
        });

        resourceIds.map((resourceId) => {
            urlParams += '&resource_ids[]=' + resourceId;
        });
    }

    return axios.get(`/api/admin/bookings/timeline` + urlParams);
}

export const getBookingsApi = (query = null) => {
    return axios.get(`/api/admin/bookings${query ? ('?' + new URLSearchParams(query).toString()) : ''}`)
}

export const deleteBookingApi = (id) => {
    return axios.delete(`/api/admin/bookings/` + id )
}


