import { dateFromString, dateNextYear } from "@/service/DateTime";

export const createTaskListFromApiData = (data, startDate, endDate) => {
    if (typeof startDate === 'string') startDate = dateFromString(startDate);
    if (typeof endDate === 'string') endDate = dateFromString(endDate);

    if (!endDate) {
        endDate = dateNextYear();
    }

    const tasks = [];

    data.forEach(rawData => {
        let parentTask = findParentTask(tasks, generateParentTaskId(rawData.resource.id));

        if (!parentTask) {
            parentTask = createParentTaskFromApiData(rawData.resource, startDate, endDate);
            tasks.push(parentTask);
        }

        tasks.push(createTaskFromApiData(rawData, parentTask.id, startDate, endDate));
    });

    if (tasks.length === 0) {
        tasks.push(createPlaceHolderTaskFromApiData(startDate, endDate));
    }

    return tasks;
};

export const createTaskListByPhaseFromApiData = (data, startDate, endDate) => {
    if (typeof startDate === 'string') startDate = dateFromString(startDate);
    if (typeof endDate === 'string') endDate = dateFromString(endDate);

    if (!endDate) {
        endDate = dateNextYear();
    }

    const tasks = [];

    data.forEach(rawData => {
        let parentTask = findParentTask(tasks, generateParentTaskId(rawData.project.id));

        if (!parentTask) {
            parentTask = createPhaseParentTaskFromApiData(rawData.project, rawData.phase, startDate, endDate);
            tasks.push(parentTask);
        }

        tasks.push(createPhaseTaskFromApiData(rawData, parentTask.id, startDate, endDate));
    });

    if (tasks.length === 0) {
        tasks.push(createPlaceHolderTaskFromApiData(startDate, endDate));
    }

    return tasks;
};

const generateParentTaskId = (rawId) => 'parent_' + rawId;

const generateTaskId = (rawId) => 'task_' + rawId;

const findParentTask = (tasks, parentTaskId) => {
    return tasks.find((task) => task.id === parentTaskId);
}

export const createParentTaskFromApiData = (resourceRawData, startDate, endDate) => {
    return {
        id: generateParentTaskId(resourceRawData.id),
        description: resourceRawData.first_name + ' ' + resourceRawData.last_name,
        start: startDate,
        end: endDate,
        progress: 0,
        type: 'task',
        style: {
            base: {
                display: 'none',
            }
        }
    };
};

export const createPhaseParentTaskFromApiData = (projectRawData, phaseRawData, startDate, endDate) => {
    return {
        id: generateParentTaskId(projectRawData.id),
        description: projectRawData.name + ' ' + phaseRawData.name,
        start: startDate,
        end: endDate,
        progress: 0,
        type: 'task',
        style: {
            base: {
                display: 'none',
            }
        }
    };
};

export const createTaskFromApiData = (taskRawData, parentId, startDate, endDate) => {
    const taskStartDate = dateFromString(taskRawData.start_date);

    const task = {
        id: generateTaskId(taskRawData.id),
        label: taskRawData.skill.name + ' - ' + taskRawData.hours_per_day + 'h',
        description: taskRawData.project.name + ' - ' + taskRawData.phase.name,
        start: (taskStartDate > startDate) ? taskStartDate : startDate,
        end: taskRawData.end_date ? dateFromString(taskRawData.end_date) : endDate,
        progress: 0,
        type: 'task',
        parentId,
        style: {
            base: {
                fill: '#000000',
                stroke: '#CCCCCC',
            },
        },
    };

    if (typeof taskRawData.project && taskRawData.project.color) {
        task.style.base.fill = taskRawData.project.color;
    }

    return task;
};

export const createPhaseTaskFromApiData = (taskRawData, parentId, startDate, endDate) => {
    const taskStartDate = dateFromString(taskRawData.start_date);

    const task = {
        id: generateTaskId(taskRawData.project.id + '_' + taskRawData.phase.id + ' ' + taskRawData.resource.id),
        label: taskRawData.skill.name + ' - ' + taskRawData.hours_per_day + 'h',
        description: taskRawData.resource.first_name + ' ' + taskRawData.resource.last_name,
        start: (taskStartDate > startDate) ? taskStartDate : startDate,
        end: taskRawData.end_date ? dateFromString(taskRawData.end_date) : endDate,
        progress: 0,
        type: 'task',
        parentId,
        style: {
            base: {
                fill: '#000000',
                stroke: '#CCCCCC',
            },
        },
    };

    if (typeof taskRawData.project && taskRawData.project.color) {
        task.style.base.fill = taskRawData.project.color;
    }

    return task;
};

export const createPlaceHolderTaskFromApiData = (startDate, endDate) => {
    return {
        id: 'placeholder',
        description: '',
        start: startDate,
        end: endDate,
        progress: 0,
        type: 'task',
        style: {
            base: {
                display: 'none',
            }
        }
    };
};