<template>
    <main :class="{'h-100': loadPage}">
        <teleport to="#modals">
            <transition name="fade">
                <Modal
                v-if="openModalDelete"
                class="modal"
                :closeButton="false"
                @close="openModalDelete = false"
                >
                <template #header>
                    <div class="flag">
                    <div class="flag__icon">
                        <SvgIcon :icon="svgIcons.modalPen"
                                    icon-width="48px"
                                    icon-height="48px"/>
                    </div>
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">Delete Booking</h2>
                        
                    </div>
                    </div>
                </template>
                <template #body>
                    <p class="mb0">Are you sure you want to delete <strong>- {{deleteBookingValue}} -</strong></p>
                </template>
                <template #footer>
                    <div class="o-flex modal-button-wrapper">
                    <a
                        href="#"
                        class="button button--secondary"
                        @click="openModalDelete = false"
                        >Cancel</a
                    >
                    <button type="button" :disabled="workingDelete" @click="deleteBooking" class="button button--primary">Delete</button>
                    </div>
                </template>
                </Modal>
            </transition>
        </teleport>
        <div class="card">
            <div v-if="!loadPage" class="card__header card--border o-flex o-flex--center o-flex--justify o-flex--wrap pt-">
                <div class="flag">
                    <div class="flag__icon">
                        <img src="@/assets/img/dashboard-chart.svg" alt="dashboard-chart" />
                    </div>
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">Bookings</h2>
                    </div>
                </div>
            </div>

            <div class="card__body" :class="{'h-100': loadPage}">
                <Loader v-if="loadPage"/>
                <div class="table-responsive" v-else>
                    <table class="table">
                        <thead>
                        <tr>
                            <th>Project</th>
                            <th>Phase</th>
                            <th>Owner</th>
                            <th>Resources</th>
                            <th>Skills</th>
                            <th width="10%" class="u-text-center">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data" :key="item.id">
                                <td>
                                    <div class="o-flex o-flex--center">
                                        <span class="color-box-mark mr-" :style="`background-color: ${item.project?.color}`"></span>
                                        {{item.project?.name}}
                                    </div>
                                </td>
                                <td>
                                    {{ item.phase?.name }}
                                </td>
                                <td>
                                    {{filterProjectManager(item.project?.project_manager_id)}}
                                </td>
                                <td>
                                    <ul class="mb0">
                                        <li v-for="booking in item.bookings" :key="booking.id">
                                              {{ booking.resource?.first_name }}
                                              {{ booking.resource?.last_name }}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul class="mb0">
                                        <li v-for="booking in item.bookings" :key="booking.id">
                                              {{ booking.skill?.name }}
                                        </li>
                                    </ul>
                                </td>
                                <td>
                                    <ul
                                        class="o-flex o-flex--center o-flex--justify-center action-list"
                                    >
                                        <li>
                                            <a href="javascript:;" class="icon-trigger" @click="deleteModalHandler(item.id, item.project?.name)">
                                                <SvgIcon  :icon="svgIcons.delete"
                                                    icon-width="20px"
                                                    icon-height="20px"/>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> 
   </main>
</template>
<script>
import Loader from '@/components/Loader/Loader.vue';
import SvgIcon from '@/components/SvgIcons/SvgIcons.vue'
import svgIcons from '@/assets/icons/icons'; 
import Modal from '../../components/Modal/Modal.vue';
import { getBookingsApi, deleteBookingApi } from '../../api/bookings'; 
import { getProjectManagers } from '../../service/general';
import { toast } from 'vue3-toastify';

export default {
    name: "Bookings",
    components: {Loader, SvgIcon, Modal},
    data() {
        return {
            svgIcons,
            loadPage: false,
            data: [],
            allProjectManagers: null,
            openModalDelete: false,
            workingDelete: false,
            deleteBookingValue: '',
            bookingId: null,
        }
    },

    methods: {

        filterProjectManager(id) {
            const manager = this.allProjectManagers.find((manager) => manager.id === id);
            return manager ? manager.first_name + ' ' + manager.last_name : null;
        },

        deleteModalHandler(id, name) {
            this.deleteBookingValue = name;
            this.bookingId = id;
            this.openModalDelete = true;

        },

        async getBookings() {
            this.loadPage = true;
            try {
                const { data } = await getBookingsApi();
                this.data = data.data
                this.loadPage = false;

            } catch (e) {
                if (e.response?.status !== 404) {
                toast.error(e.response?.data?.message || 'An error occurred', {
                    position: 'top-center',
                    autoClose: 2000,
                });
                }
                return null;
            }
        },

        async deleteBooking() {
            this.workingDelete = true;
            try {
                await deleteBookingApi(this.bookingId);
                toast.success(this.deleteBookingValue + " is deleted successfuly.", {"position": "top-center", autoClose: 2000});
                this.bookingId = '';
                this.openModalDelete = false;
                this.workingDelete = false;
                this.getBookings();
            } catch (e) {
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
                setTimeout(() => {
                this.workingDelete = false;
                }, "3000");
            }
        },
    },
    async created() {
        const [ projectManagers] = await Promise.all([
            getProjectManagers(),
        ]);

        this.allProjectManagers = projectManagers
    },
    mounted() {
        
        this.getBookings();
    },
}
</script>
<style lang="scss" scoped>
    td {
        vertical-align: top;
    }

    .modal-button-wrapper {
        gap: 16px;

        .button {
            height: 44px;
        }
    }
</style>