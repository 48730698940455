import moment from "moment";

export const dateStringToUnixStamp = (dateString) => {
    return new moment(dateString).unix() * 1000;
};

export const unixStampToDateString = (unixStamp) => {
    return new moment(unixStamp * 1000).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]');
}

export const dateFromString = (dateString) => {
    return new moment(dateString);
};

export const dateNow = () => {
    return new moment();
};

export const dateYesterday = () => {
    return new moment().subtract(1, 'day');
};

export const dateNextYear = () => {
    return new moment().add(1, 'year');
};

export const datePickerFormatFromString = (dateString) => {
    return new moment(dateString).format('YYYY-MM-DD');
};

export const getYearStringFromDate = (date) => {
    if (typeof date === 'string') {
        date = new moment(date);
    }

    return date.format('YYYY');
};

export const getDayMonthStringFromDate = (date) => {
    if (typeof date === 'string') {
        date = new moment(date);
    }

    return date.format('DD/MM/YYYY');
};

export const getMonthStringFromDate = (date) => {
    if (typeof date === 'string') {
        date = new moment(date);
    }

    return date.format('MMMM YYYY');
};

export const dateAddWeek = (date) => {
    if (typeof date === 'string') {
        date = new moment(date);
    }

    return date.add(1, 'week');
};

export const dateSubWeek = (date) => {
    if (typeof date === 'string') {
        date = new moment(date);
    }

    return date.subtract(1, 'week');
};

export const dateAddMonth = (date) => {
    if (typeof date === 'string') {
        date = new moment(date);
    }

    return date.add(1, 'month');
};

export const dateSubMonth = (date) => {
    if (typeof date === 'string') {
        date = new moment(date);
    }

    return date.subtract(1, 'month');
};

export const dateAddYear = (date) => {
    if (typeof date === 'string') {
        date = new moment(date);
    }

    return date.add(1, 'year');
};

export const dateSubYear = (date) => {
    if (typeof date === 'string') {
        date = new moment(date);
    }

    return date.subtract(1, 'year');
};
