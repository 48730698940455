<template>
    <router-view />
</template>
<script>

import { RouterView } from 'vue-router'
export default {
    name:"Estimations",
    components: {
        RouterView,
    }
}
</script>
<style lang="scss" scoped>
    
</style>
