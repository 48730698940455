<template>
    <div class="card">
        <div class="card__header o-flex o-flex--center o-flex--justify">
            <h2 class="u-font-600 mb0">Required skills</h2>
            <a href="javascript:;" class="accordion-trigger" @click="accordionActive = !accordionActive" :class="{'active': accordionActive}">
                <svg fill="none" width="16" viewBox="0 0 16 16">
                    <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m4 10 4-4 4 4"/>
                </svg>
            </a>
        </div>
        <div class="card__body pb0 pt0 mt0 border-none">
            <div class="table-responsive">
                
                <table class="table mb0">
                    <thead>
                        <tr>
                            <th>
                                Skill
                            </th>
                            <th>
                                Required Hours
                            </th>
                            <th>
                                Start Date
                            </th>
                            <th>
                                End Date
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="phase in skills" :key="phase.id">
                            <td>
                                {{phase.name}}
                            </td>
                            <td>    
                                {{phase.hours === null ? 'Ongoing': phase.hours}}
                            </td>

                            <td>
                                <vue-date-picker
                                    class="date-table"
                                    :enable-time-picker="false"
                                    auto-apply
                                    :teleport="true"
                                    :model-type="'yyyy-MM-dd'"
                                    :format="'dd.MM.yyyy'"
                                    placeholder="Select Date"
                                    v-model="phase.startDate"
                                    :ui="{ input: 'date-picker' }" 
                                >
                                    <template #input-icon>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3 10V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V10M3 10H21M3 10V6C3 4.89543 3.89543 4 5 4H7M21 10V6C21 4.89543 20.1046 4 19 4H18.5M15 4V2M15 4V6M15 4H10.5M7 6V2" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </template>
                                </vue-date-picker>
                            </td>
                            <td>
                                <vue-date-picker 
                                    v-if="phase.hours !== null"
                                    class="date-table"
                                    auto-apply
                                    :teleport="true"
                                    :enable-time-picker="false"
                                    :model-type="'yyyy-MM-dd'"
                                    :format="'dd.MM.yyyy'"
                                    placeholder="Select Date"
                                    v-model="phase.endDate"
                                    name="endDate"
                                    :ui="{ input: 'date-picker' }"
                                >
                                    <template #input-icon>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3 10V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V10M3 10H21M3 10V6C3 4.89543 3.89543 4 5 4H7M21 10V6C21 4.89543 20.1046 4 19 4H18.5M15 4V2M15 4V6M15 4H10.5M7 6V2" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </template>
                                </vue-date-picker>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card__footer card--border o-flex o-flex--right pb0 border-none">
            <div class="o-flex o-flex--center gap-10">
                <router-link :to="{name: 'project'}" class="button button--secondary">Cancel</router-link>
                <button type="button" @click="submitAnalyze" class="button button--primary" >Analyze</button>
            </div>
        </div>
    </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from "moment";
import { toast } from "vue3-toastify";
import {
    analyzeProjectPhase,
} from '@/api/bookings';

export default {
    name:"RequiredSkills",
    props: {
        payload: {
            type: Object,
        }
    },
    emits: ['submit'],
    components: {
        VueDatePicker
    },
    data() {
        return {
            accordionActive: true,
            skills: [],
        }
    },
    watch: {
        payload: {
            handler(newPayload) {
                this.parseSkills(newPayload);
            },
            immediate: true,
            deep: true, 
        }
    },

    methods: {
        parseSkills(payload) {
            if (!payload || !payload.skills || !Array.isArray(payload.skills)) {
                this.skills = []; 
                return;
            }

            this.skills = payload.skills.map(item => ({
                id: item.id,
                name: item.name,
                hours: item.pivot.hours,
                startDate: payload.start_date ? moment(payload.start_date).format('YYYY-MM-DD') : null,
                endDate: payload.end_date ? moment(payload.end_date).format('YYYY-MM-DD') : null,
            }));
        },
        async submitAnalyze() {
            const skills = this.skills.map(item => ({
                id: item.id,
                start_date: item.startDate,
                ...(item.endDate && { 
                    end_date: moment(item.endDate, 'YYYY-MM-DD') 
                }),
            }));
            
            try {
                await analyzeProjectPhase(this.payload.id, {skills})
                this.$emit('submit', 'AvailableResources')

            } catch (e) {
                toast.error(e.response?.data.message, {"position": "top-center", autoClose: 2000});
                setTimeout(() => {
                    this.working = false;
                }, "3000");
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .date-table {
        max-width: 480px;
    }
</style>