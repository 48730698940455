<template>
    <div class="card h-100" v-if="loadPage">
        <div class="card_body h-100">
            <Loader/>
        </div>
    </div>
    <Form v-else :validation-schema="schema">
        <div class="card">
            <div class="card__header o-flex o-flex--center o-flex--justify">
                <div class="flag">
                    <div class="flag__icon">
                        <img src="@/assets/img/add-project.svg" alt="add-project" />
                    </div> 
                    <div class="flag__content">
                        <h2 class="u-font-500 mb0">New Estimation</h2>
                    </div>
                </div>
            </div>
            <transition name="fade">
                <div class="card__body">
                    <div class="row">
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div class="input-box">
                                <p>Estimation name <span>*</span></p>
                                <div class="relative">
                                    <Field type="text" name="estimationName" class="input input--primary" :placeholder="'Enter'" v-model="estimationName"/>
                                    <ErrorMessage class="text-error" name="estimationName"  />
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div class="input-box">
                                <p>Client name <span>*</span></p>
                                <div class="relative">
                                    <VueMultiselect name="teamLead" v-model="clientSelected" placeholder="Select" :options="allClients" label="name" track-by="name"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div class="input-box">
                                <p>Project Manager <span>*</span></p>
                                <div class="relative">
                                    <VueMultiselect name="teamLead" v-model="projectManagerSelected" placeholder="Select" :options="formattedProjectManagers" label="name" track-by="name"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div class="input-box">
                                <p>Status <span>*</span></p>
                                <div class="relative">
                                    <VueMultiselect name="teamLead" v-model="statusSelected" placeholder="Select" :options="statuses" label="name" track-by="name"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div class="input-box">
                                <p>Project currency <span>*</span></p>
                                <div class="relative">
                                    <VueMultiselect
                                        v-model="currencySelected"
                                        placeholder="Select"
                                        label="name"
                                        track-by="name"
                                        :options="allCurrencies"
                                        class="selectIcon"
                                        >
                                        <template #singleLabel="value">
                                            <div class="multiselect-inline">
                                                <div class="multiselect-icon">
                                                    <img class="character-label-icon" :src="value.option.icon">
                                                </div> 
                                                {{ value.option.name }}
                                            </div>
                                        </template>
                                        <template #option="props">
                                            <div class="multiselect-inline">
                                                <img class="character-option-icon" :src="props.option.icon"> {{ props.option.name }}
                                            </div>
                                        </template>
                                    </VueMultiselect>
                                    <!-- <template v-if="currencySelected && currencySelected.name === 'USD'">
                                        USD icon
                                    </template>
                                    <template v-if="currencySelected && currencySelected.name === 'EUR'">
                                        EUR icon
                                    </template> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div class="input-box">
                                <p>VAT <span>*</span></p>
                                <div class="date-box date-box--right input--primary">
                                    <div class="icon-wrapper">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.8334 1.52325L1.16669 13.1899M9.50002 11.5233C9.50002 12.4437 10.2462 13.1899 11.1667 13.1899C12.0872 13.1899 12.8334 12.4437 12.8334 11.5233C12.8334 10.6028 12.0872 9.85659 11.1667 9.85659C10.2462 9.85659 9.50002 10.6028 9.50002 11.5233ZM1.16669 3.18992C1.16669 4.1104 1.91288 4.85659 2.83335 4.85659C3.75383 4.85659 4.50002 4.1104 4.50002 3.18992C4.50002 2.26945 3.75383 1.52325 2.83335 1.52325C1.91288 1.52325 1.16669 2.26945 1.16669 3.18992Z" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <Field type="number" name="vat" class="input input--primary" :placeholder="'Enter'" v-model="vat"/>
                                    <ErrorMessage class="text-error" name="vat"  />
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-12 col-lg-12">
                            <div class="input-box">
                                <p>Description</p>
                                <div class="relative">
                                    <Field as="textarea" type="text" name="description" class="input input--primary input--textarea" :placeholder="'Message...'" v-model="description" maxlength="250"/>
                                    <span class="max-characters">{{ description?.length }}/250</span>
                                    <ErrorMessage class="text-error" name="description"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="divider divider-1 mb"></div>
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="input-box">
                                <p>Employees</p>
                                <div class="relative">
                                    <VueMultiselect
                                    v-model="employees"
                                    placeholder="Select"
                                    :options="allEmployees"
                                    :taggable="true"
                                    :multiple="true"
                                    track-by="id"
                                    :custom-label="allEmployeesLabel"
                                    :show-labels="false"
                                    :close-on-select="true"
                                    class="input--tags"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 class="subcaption"><strong>Selected skills</strong></h2>

                    <div class="row row--end" v-for="(skill, index) in requiredSkills" :key="index">
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-3">
                            <div class="input-box">
                                <p>Skill</p>
                                <div class="relative">
                                    <VueMultiselect v-model="skill.skillSelected" placeholder="Select" :options="availableSkills" label="name" track-by="id"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-2">
                            <div class="input-box">
                                <p>Hours</p>
                                <div class="date-box date-box--right input--primary">
                                    <div class="icon-wrapper">
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 5.71319L10 10.7132L15 10.7132M18.3334 10.7132C18.3334 15.3156 14.6024 19.0465 10 19.0465C5.39765 19.0465 1.66669 15.3156 1.66669 10.7132C1.66669 6.11081 5.39765 2.37985 10 2.37985C14.6024 2.37985 18.3334 6.11081 18.3334 10.7132Z" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <InputNumber :placeholder="'Enter'" :hour="skill.hours" :max="4" @number="(value) => setSkillHours(value, index)"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-2">
                            <div class="input-box">
                                <p>Cost (per hour)</p>
                                <div class="date-box date-box--right input--primary">
                                    <div class="icon-wrapper">
                                        <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.46162 4.31809C7.67125 3.52772 6.27933 2.97797 5.0001 2.94354M1.53857 11.8181C2.2822 12.8096 3.66484 13.3751 5.0001 13.423M5.0001 2.94354C3.47804 2.90258 2.11549 3.59115 2.11549 5.47194C2.11549 8.93345 8.46162 7.2027 8.46162 10.6642C8.46162 12.6385 6.7726 13.4867 5.0001 13.423M5.0001 2.94354V0.856567M5.0001 13.423V15.8565" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <InputNumber :placeholder="'Enter'" :hour="skill.cost" :max="10" @number="(value) => setSkillCost(value, index)"/>
                                    <!-- <Field type="number" id="MyId" name="cost" class="input input--primary" :placeholder="'Enter'" v-model="cost"/> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-2">
                            <div class="input-box">
                                <p>Total</p>
                                <div class="date-box date-box--right input--primary">
                                    <div class="icon-wrapper">
                                        <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.46162 4.31809C7.67125 3.52772 6.27933 2.97797 5.0001 2.94354M1.53857 11.8181C2.2822 12.8096 3.66484 13.3751 5.0001 13.423M5.0001 2.94354C3.47804 2.90258 2.11549 3.59115 2.11549 5.47194C2.11549 8.93345 8.46162 7.2027 8.46162 10.6642C8.46162 12.6385 6.7726 13.4867 5.0001 13.423M5.0001 2.94354V0.856567M5.0001 13.423V15.8565" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    {{ skill.hours * skill.cost }}
                                    <!-- <Field type="number" name="cost" class="input input--primary" :placeholder="'Enter'" v-model="cost"/> -->
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-xs-12 col-md-3 col-lg-2 col-xl-2">
                            <div class="input-box">
                                <p>Total</p>
                                <div class="date-box date-box--right input--primary">
                                    <div class="icon-wrapper">
                                        <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.46162 4.31809C7.67125 3.52772 6.27933 2.97797 5.0001 2.94354M1.53857 11.8181C2.2822 12.8096 3.66484 13.3751 5.0001 13.423M5.0001 2.94354C3.47804 2.90258 2.11549 3.59115 2.11549 5.47194C2.11549 8.93345 8.46162 7.2027 8.46162 10.6642C8.46162 12.6385 6.7726 13.4867 5.0001 13.423M5.0001 2.94354V0.856567M5.0001 13.423V15.8565" stroke="#637083" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <Field type="number" name="total" class="input input--primary" :placeholder="'Enter'" v-model="total"/>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-xs-12 col-md-6 col-lg-6 col-xl-1 o-flex o-flex--start">
                            <div class="o-flex gap-10 button-wrapper mb-16">
                                <a href="javascript:;" @click="addRow" v-if="index === requiredSkills.length - 1 && requiredSkills.length < topSkills.length" class="button button--square">
                                    <svg fill="none" width="20" viewBox="0 0 20 21">
                                        <path stroke="#344051" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5 10.357h5m5 0h-5m0 0v-5m0 5v5"/>
                                    </svg>
                                </a>
                                <a href="javascript:;" @click="deleteRow(index)" class="button button--square" v-if="requiredSkills.length > 1">
                                    <svg fill="none" width="17" viewBox="0 0 18 19">
                                        <path stroke="#344051" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m15.667 6.857-1.663 9.455a1.667 1.667 0 0 1-1.641 1.378H5.637c-.809 0-1.501-.581-1.641-1.378L2.333 6.857m14.167-2.5h-4.688m0 0V2.69c0-.92-.746-1.667-1.666-1.667H7.854c-.92 0-1.667.746-1.667 1.667v1.667m5.625 0H6.189m-4.688 0h4.688"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="sum o-flex o-flex--column" v-for="(skill, index) in estimationTotal" :key="index">
                        <div class="sum__item o-flex o-flex--right o-flex--center">
                            <span class="sum__sub">Subtotal</span>
                            <strong class="sum__subtotal">${{ skill.subtotal }}</strong>
                        </div>
                        <div class="sum__item o-flex o-flex--right o-flex--center">
                            <span class="sum__sub">VAT</span>
                            <strong class="sum__subtotal">${{ skill.vat }}</strong>
                        </div>
                        <div class="sum__item o-flex o-flex--right o-flex--center">
                            <span class="sum__sub sum__sub--lg">Total</span>
                            <strong class="sum__total">${{ skill.total }}</strong>
                        </div>
                    </div> -->

                    <div class="sum o-flex o-flex--column">
                        <div class="sum__item o-flex o-flex--right o-flex--center">
                            <span class="sum__sub">Subtotal</span>
                            <strong class="sum__subtotal">${{ totalSubtotal }}</strong>
                        </div>
                        <div class="sum__item o-flex o-flex--right o-flex--center">
                            <span class="sum__sub">VAT</span>
                            <strong class="sum__subtotal">${{ totalVat }}</strong>
                        </div>
                        <div class="sum__item o-flex o-flex--right o-flex--center">
                            <span class="sum__sub sum__sub--lg">Total</span>
                            <strong class="sum__total">${{ totalCost }}</strong>
                        </div>
                    </div>

                </div>
            </transition>

            <transition name="fade">
                <div class="card__footer gap-10 o-flex o-flex--justify o-flex--row-reverse o-flex--wrap">
                    <div class="card__btns o-flex o-flex--center gap-10">
                        <router-link :to="{name: 'estimations'}" class="button button--secondary">Cancel</router-link>
                        <button type="button" @click="submit" class="button button--primary" :disabled="disabledButton">Save Changes</button>
                    </div>
                    <button type="button" class="button button--delete">Delete</button>
                </div>
            </transition>
        </div>
    </Form>
</template>
<script>
import VueMultiselect from 'vue-multiselect';
import Loader from '../../components/Loader/Loader.vue';
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { getClients, getProjectManagers, getTopSkills, getEmployees } from '../../service/general';
import { status } from '@/utilities/status';
import InputNumber from '@/components/InputNumber/InputNumber.vue';
// Project currency icons
import usIcon from '@/assets/img/us.svg';

export default {
    name: "EstimateForm",
    components: {
        Loader,
        Field,
        Form,
        ErrorMessage,
        VueMultiselect,
        InputNumber,
    },
    props: {
        payload: {
            type: Object
        },
    },
    data() {
        return {
            loadPage: true,
            estimationName: "",
            clientSelected: null,
            allClients: [],
            projectManagerSelected: null,
            allProjectManagers: [],
            statusSelected: null,
            allStatuses: [],
            vat: null,
            currencySelected: null,
            allCurrencies: [
                { name: 'USD', icon: usIcon },
                { name: 'EUR', icon: usIcon },
                { name: 'GBP', icon: usIcon },
            ],
            description: '',
            topSkills: [],
            requiredSkills: [],
            hours: "",
            cost: "",
            total: "",
            vatCalculated: "",
            employees: [],
            allEmployees: [],
            schema: yup.object({
                estimationName: yup
                .string()
                .required('Estimation name is required')
                .min(2, 'Must be at least 2 characters'),
                clientSelected: yup
                .string()
                .required('Client name is required'),
                projectManagerSelected: yup
                .string()
                .required('Project Manager is required'),
                statusSelected: yup
                .string()
                .required('Status is required'),
                currencySelected: yup
                .string()
                .required('Project currency is required'),
                vat: yup
                .string()
                .required('VAT is required'),
            }),
        }
    },
    computed: {
        disabledButton() {
            return this.estimationName === '' ||
            this.clientSelected === null ||
            this.projectManagerSelected === null ||
            this.statusSelected === null ||
            this.currencySelected === null ||
            (this.vat === null || this.vat === '');
        },
        statuses() {
            return this.allStatuses.filter(item => 
                item.name === "not-started" || 
                item.name === "in-progress" || 
                item.name === "on-hold" || 
                item.name === "maintenance" || 
                item.name === "completed"
            );
        },
        availableSkills() {
            const selectedSkillIds = this.requiredSkills
                .map(skill => skill.skillSelected?.id) 
                .filter(id => id !== undefined); 
            return this.topSkills.filter(skill => !selectedSkillIds.includes(skill.id));
        },
        formattedProjectManagers() {
            return this.allProjectManagers.map(manager => ({
                ...manager,
                name: `${manager.first_name} ${manager.last_name}`,
            }));
        },
        // estimationTotal() {
        //     const vatValue = this.vat;

        //     return this.requiredSkills.map(skill => {
        //         const subtotal = skill.hours * skill.cost; 
        //         const vat = (subtotal / 100) * vatValue;
        //         const total = subtotal + vat;
        //         return {
        //             ...skill,
        //             subtotal: subtotal, 
        //             vat: vat,
        //             total: total,
        //         };
        //     });
        // },
        totalSubtotal() {
            return this.requiredSkills.reduce((total, skill) => {
                return total + (parseFloat(skill.hours) || 0) * (parseFloat(skill.cost) || 0);
            }, 0);
        },
        totalVat() {
            return (this.totalSubtotal / 100) * (parseFloat(this.vat) || 0);
        },
        totalCost() {
            return this.totalSubtotal + this.totalVat;
        }

    },
    watch: {
        totalSubtotal: {
            immediate: true,
            handler(newVal) {
              console.log(newVal)

            },
        },
    },
    methods: {
        setSkillHours(value, index) {
            this.requiredSkills[index].hours = value;
        },

        setSkillCost(value, index) {
            this.requiredSkills[index].cost = value;
        },

        updateData(payload) {
            if (payload && Object.keys(payload).length > 0) {
                if (payload.estimationName !== undefined) this.estimationName = payload.estimationName;
                if (payload.client !== undefined) this.clientSelected = payload.client;
                if (payload.project_manager !== undefined) {
                    const { first_name, last_name } = payload.project_manager;

                    this.projectManagerSelected = {
                        ...payload.project_manager,
                        name: `${first_name} ${last_name}`
                    };
                };
                if (payload.status !== undefined) {
                    const selectedStatus = status.find(s => s.name === payload.status);
                    if (selectedStatus) {
                        this.statusSelected = { id: selectedStatus.id, name: selectedStatus.name };
                    }
                }
                if (payload.currency !== undefined) this.currencySelected = payload.currency;
                if (payload.vat !== undefined) this.vat = payload.vat;
                // if (payload.currency !== undefined) {
                //     const selectedCurrency = currency.find(s => s.name === payload.currency);
                //     if (selectedCurrency) {
                //         this.currencySelected = { id: selectedCurrency.id, name: selectedCurrency.name };
                //     }
                // }
                if (payload.description !== undefined) this.description = payload.description;
                if (payload.skills && Array.isArray(payload.skills)) {
                    if (payload.skills.length > 0) {
                        this.requiredSkills = payload.skills.map(skill => ({
                            skillSelected: skill || null,
                            hours: String(skill.pivot?.hours || ''),
                        }));
                    } else {
                        this.requiredSkills = [
                            {
                                skillSelected: null,
                                hours: '',
                            },
                        ];
                    }
                }
                if (payload.cost !== undefined) this.cost = payload.cost;
                if (payload.total !== undefined) this.total = payload.total;
                if (Array.isArray(payload.employees) && payload.employees.length) {
                this.employees = payload.employees;
                }

            }

            this.loadPage = false;
        },

        allEmployeesLabel ({first_name, last_name}) {
            return `${first_name} ${last_name}`
        },

        addRow() {

            const defaultSkill = {
                skillSelected: null,
                hours: null,
                cost: null,
                total: null,
            };

            this.requiredSkills.push(defaultSkill);
        },

        deleteRow(index) {
            this.requiredSkills.splice(index, 1);
        },

        async submit() {
            const skillsList = this.requiredSkills
            .filter(item => item.skillSelected !== null)
            .map(item => ({
                id: item.skillSelected.id,
                hours: item.hours,
            }));

            const employees = this.employees.map((item) => {
                return item.id
            })

            const payload = {
                estimationName: this.estimationName,
                client_id: this.clientSelected.id,
                project_manager_id: this.projectManagerSelected.id,
                status: this.statusSelected.name,
                vat: this.vat,
                employee_ids: employees,
                currency: this.currencySelected.name,
                description: this.description,
                ...(skillsList && skillsList.length > 0 && { skills: skillsList }),
                cost: parseInt(this.cost).toFixed(2),
                total: parseInt(this.total).toFixed(2),
            }
            this.$emit('formData', payload);
        },
    },
    async created() {
        const [allProjectManagers, allClients, allEmployees]  = await Promise.all([getProjectManagers(), getClients(), getEmployees()])
        this.allClients = allClients;
        this.allProjectManagers = allProjectManagers;
        this.allStatuses = status;
        // this.allCurrencies = currency;
        const [topSkills]  = await Promise.all([getTopSkills()])
        this.topSkills = topSkills;
        this.allEmployees = allEmployees;
        this.addRow();
        this.updateData(this.payload);
    },
}
</script>
<style lang="scss" scoped>
.subcaption {
    margin: 8px 0 24px;
}
.sum {
    gap: 12px;
    padding: 26px 0;
    margin: 0 0 10px;
    border-top: 1px solid var(--light-gray);
    border-bottom: 1px solid var(--light-gray);

    &__item {
        gap: 44px;
    }

    &__sub {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        text-transform: uppercase;
        color: var(--dark);

        &--lg {
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__subtotal {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        color: var(--gray);
        width: 110px;
        text-align: right;
    }

    &__total {
        font-size: 18px;
        line-height: 28px;
        font-weight: 700;
        color: var(--dark);
        width: 110px;
        text-align: right;
    }
}
</style>